import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl,  config, ImagesUrl, Currency, FormatNumber} from '../component/include'
import axios from 'axios'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'

import Cookies from 'js-cookie'
import {encrypt} from '../component/authentication'

//var CryptoJS = require('crypto-js')

 const Registration =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	const [applicationType, setApplicationType]= useState([])
    const [CurrentSession, setCurrentSession] = useState('')
	const [isSubmitting, setIsSubmitting] = useState(false);

    const [errors, setErrors] = useState({});
    const [activeStep, setActiveStep] = useState(1);
	const [applicant, setApplicant] = useState({
        code:Math.random().toString(36).substr(2,9),
		email:'',
		password:'',
		gender:'Male',
        fullname:'',
        applicationType:'',
        confirmPassword:'',
        schoolAttended:JSON.stringify([
            {code:1, institution:"", program:"", grade:"",  remarks:"",  awardYear:""},
            {code:2, institution:"", program:"", grade:"",  remarks:"",  awardYear:""},
        ])
    })

    const nextStep =()=>setActiveStep(Number(activeStep)+1)
    const peviousButton =()=>setActiveStep(Number(activeStep)-1)
    
const getAppName =(code)=>{
    const ans = applicationType&&applicationType.filter(item=>item.ID===code)
    return ans.length!==0?ans[0].applicationName:''
}


var today = new Date().toISOString().slice(0,10);

    const fetchApplication =()=>{

        var sql ="select t.applicationName, a.startDate, a.feeCharge, a.endDate, a.dateCreate, a.ID, s.startYear from tbl_admission_application t, tbl_school_admission a, tbl_school_session s  where s.ID = a.schoolSession and t.ID = a.applicationType and a.startDate <= '"+today+' 00:00:01'+"' and a.endDate >=  '"+today+' 23:59:59'+"'  order by a.ID DESC "
            
        const fd = new FormData();
        fd.append("sql", sql);
        let url = ServerUrl+'/fetch_CarelesSql_controller';
        axios.post(url, fd, config).then(result=>{

            if(result.data.length!==0){
                setApplicationType(result.data)
            }
            
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }

    const handleChangeType = (code) =>{		
       setApplicant({...applicant, applicationType : code });
       setActiveStep(Number(activeStep)+1)
    }


    const handleChange = event =>{		
        let {name, value} = event.target;	
       setApplicant({...applicant, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(applicant));
        setIsSubmitting(true);
    }


    const  handleSession =()=>{

        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        //fd.append("jwt", Token);
        let url = ServerUrl+'/fetch_CarelesSql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        }


    const ValidateInput =(values)=>{
        let errors = {};   
        let email = values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        
        if(!values.fullname){
            errors.fullname ='Please enter fullname';
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }

		if(!values.applicationType){
            errors.applicationType ='Please select application type';
        }
 
        if(values.password.length <6){
              errors.password ='Please enter minimum of 6 characters';
          } 

        if(values.password!==values.confirmPassword){
            errors.password ='Password does not match.';
        }
        
        return errors;
        }

        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])
	
	

 function submit(){  
                setNotice({...notice, 
                  isLoading: true})     
              
            let formData = applicant;
            const fd = new FormData();
            for(let k in formData){
            fd.append(k, formData[k])
            }
            fd.append("schoolSession", CurrentSession)
            
            
            let url = ServerUrl+'/save_controller.php?tablename=tbl_admission_request';
            axios.post(url, fd, config)
            .then(response =>{
            if(response.data.type === 'success'){
                
              window.scrollTo(0,0)
              setErrors({...errors, successMessage: response.data.message})

              setTimeout(()=>{




//1000 * 60 * SESSION_IDEL_MINUTES
var inSixtyMinutes = Date.now() + 419 * 60 * 1000;
var inOneMinutes = Date.now() + 418 * 60 * 1000;
var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
      
      

        Cookies.set("_pagetmapp", inOneMinutes, { expires: inOneHours});
        Cookies.set("_pagebtmapp", inSixtyMinutes, { expires: inOneHours });

    Cookies.set('pageappstnm', encrypt(applicant.fullname), {expires: inOneHours })
    Cookies.set('pageapptk', encrypt(response.data.jwt),  {expires: inOneHours }) 
    Cookies.set('pageappid', encrypt(response.data.applicationID),  {expires: inOneHours })   


    window.open('/overview', '_self')
               

              },3000)
                      } else{
                    //Alerts('Error!', 'danger', response.data)
                    window.scrollTo(0,0)
              setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                      }   
                  })
                  .catch((error)=>{
                    Alerts('Error!', 'danger', error.message)
                  }).finally(()=>{

                    setApplicant({
                        email:'',
		password:'',
		gender:'Male',
        fullname:'',
        applicationType:'',
        password:'',
        confirmPassword:'',
        schoolAttended:JSON.stringify([
            {code:1, institution:"", program:"", grade:"",  remarks:"",  awardYear:""},
            {code:2, institution:"", program:"", grade:"",  remarks:"",  awardYear:""},
        ])

                    })
                    setNotice({...notice, 
                      isLoading: false
                    })
                    setActiveStep(1)
                    setIsSubmitting(false);
                  }) 
              }

              const ValidateExistence=()=>{
                if(applicant.email!==''){
                       
                    const fd = new FormData();
                    fd.append("data", applicant.email)
                    let url = ServerUrl+'/existence_controller.php?tablename=tbl_admission_request&column=email';
                    axios.post(url, fd, config).then(response=>{
                        if(response.data===true){
                            setErrors({...errors, email:applicant.email + ' already register, please login to continue'})
                            setApplicant({...applicant, email:''});
                            return '';
                        }
                  })
                }
            } 


useEffect(()=>{  
   fetchApplication()
    handleSession()
},[]);


        return (<>
        
          <Preloader />
          <section style={{background: `url(${ImagesUrl}/background/a.png) `, backgroundRepeat:'no-repeat', backgroundSize:'cover'}} 
			className="login-block">
		  <div className="container">
			  <div className="row">
				  <div className="col-sm-12">
					  
		  <div className="text-center">
		  <img src={ImagesUrl+'/about/logo.png'} style={{ maxWidth:'150px', maxHeight:'150px' }}  alt="logo.png" />
		  </div>

          </div>
        </div>


		  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
          <div className="row">
				
      
        <div className="col-md-6">

        <div style={{height:'60px'}}>
        <div className="alert alert-success mt-3">
        <h3 className="text-green">NEW APPLICATION</h3>
</div>

            
</div>

	
    

                <div className=" card bg-info">
		<div className="card-block">
		
			<div className="card-body">

            <div className='text-white'><h4>BEFORE YOU START?</h4>
			<h5>Before you start the application process, there are a few things you should know.</h5>
            <br/>

			<h5 className='text-align'>1. You must give a valid email address for the account to be associated with. This email address will be used to communicate with you, so it should be one that you have access to.</h5>
<br/>
			<h5 className='text-align'>2. You must create a password that is at least 6 characters long and contains one letter, one number, or one symbol, and it must be unique.</h5>
            <br/>
			<h5 className='text-align'>3. We'll send you a verification link and send you a confirmation message when you create an account. To finish your account setup, look for the account activation email in your inbox or spam folder.</h5>

            <br/>
<h5 className='text-align'>Note: Each application type has an application cost that must be paid before the application process can be completed.</h5>
			</div>

			</div>
			
		
		</div>
        </div>    

                            </div>
                            <div className="col-md-6">
                       
                            <div style={{height:'60px'}} >
<button type="button"  className="btn btn-danger pull-right mt-3">  {activeStep} of 2 </button>
</div>
                            {errors.successMessage?
    <div className="alert alert-success mt-3">
	<p className="icontext"><i className="icon text-success fa fa-check"></i>  {errors.successMessage}</p>
</div>:''}

{errors.errorMessage?
    <div className="alert alert-danger mt-3">
	<p className="icontext"><i className="icon text-danger fa fa-times"></i> {errors.errorMessage}</p>
</div>:''}

	<form className="md-float-material form-material" role={'presentation'} >
	<div className=" card">
		<div className="card-block">
			
        {activeStep === 1 ?<div>
            <div className=" m-b-20">
				<div className="col-md-12">
					<h3 className="text-center">SELECT APPLICATION TYPE</h3>
				</div>
			</div>

<div className="row">

{applicationType.length!==0?applicationType.map((data, id)=>
            <div  className=" col-md-12 mb-2" key={id} onClick={()=>handleChangeType(data.ID)}  >

<div  style={{cursor:'pointer'}} className={applicant.applicationType===data.ID?'card user-card  bg-success':'card user-card  bg-warning'}  >
        <div className="card-body ">
            
        <h6>{data.applicationName.toUpperCase()}</h6>
            <h4>{Currency+FormatNumber(data.feeCharge)}</h4>
      
    </div></div>
</div>
):<h4 className='col-md-12 text-red'>There no open applications.</h4>}

</div>

<hr/>
			<div className="row">
				<div className="col-md-8">
					<p className="text-inverse text-left"><a href="/applicant_login" className='btn btn-inverse'><b className="f-w-600">Back to Login</b></a></p>
				</div>
				<div className="col-md-4">
               {applicant.applicationType!==''? <button type="button" onClick={nextStep} className="btn btn-success btn-md waves-effect waves-light text-center m-b-20">NEXT STEP</button>:''}
				</div>
			</div>
            
            </div>: 
<div>
<div className="row m-b-20">
				<div className="col-md-12">
					<h3 className="text-center">{getAppName(applicant.applicationType).toUpperCase()} </h3>
				</div>
			</div>

			<div className="form-group form-primary">
            <label > Child's Fullname (Surname First): </label>
				<input type="text"  name="fullname" role={'presentation'} autoComplete='off' value={applicant.fullname} placeholder="Your fullname" onChange={handleChange} className={errors.fullname  ? 'form-control formerror' : 'form-control'} /> 
				
			</div><span className=" form-bar error">{ errors.fullname}</span>

            <div className="form-group form-primary">
				
                <label > Child's Gender </label>
    <div className="form-radio">
                <div className="radio radiofill radio-inline">
            <label>
                <input type="radio" name="gender" onChange={handleChange} value='Male' checked={applicant.gender==='Male'?true:false} />
                <i className="helper"></i>Male
            </label>
        </div>
        <div className="radio radiofill radio-inline">
            <label>
                <input type="radio" name="gender" onChange={handleChange} value='Female' checked={applicant.gender==='Female'?true:false} />
                <i className="helper"></i>Female
            </label>
        </div>
        </div>

            </div>

			<div className="form-group form-primary">
            <label > Parent/Guardian's Email Address: </label>
			<input type="email" id="email" name="email" onBlur={ValidateExistence} role={'presentation'} autoComplete='off'  value={applicant.email} placeholder="Your Email Address" onChange={handleChange} className={errors.email  ? 'form-control formerror' : 'form-control'} /> 
			


			</div><span className=" form-bar error">{errors.email }</span>

          


            <div className="row">
                    <div className="col-sm-6"> <label > Account Password </label>
                        <div className="form-group form-primary">
                            <input type="password" id="password" name="password" role={'presentation'} autoComplete='off'  value={applicant.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control formerror' : 'form-control'} />
                            
                        </div><span className=" form-bar error">{errors.password }</span>
                    </div>
                    <div className="col-sm-6"><label > Repeat Password </label>
                        <div className="form-group form-primary">
                            <input type="password" name="confirmPassword" role={'presentation'} autoComplete='off' placeholder="Confirm Password"  value={applicant.confirmPassword}  onChange={handleChange} className={errors.confirmPassword  ? 'form-control formerror' : 'form-control'} />
                        </div>
                            <span className=" form-bar error">{errors.password }</span>
                    </div>
                </div><hr/>

                <div className="row mt-5">
					<div className="col-md-6 col-sm-3" >
                    
						<button type="button" onClick={peviousButton} className="btn btn-secondary "> <i className="fa fa-angle-left mr-3"></i> Previous</button>
                        </div>
                        <div className="col-md-6 col-sm-3">
                       
                        {notice.isLoading?
          
          <button disabled className="btn btn-secondary" type="button"><span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Please wait...</button>:
					<button type="button" onClick={handleSubmit} className="btn btn-success btn-block waves-effect waves-light text-center ">CREATE ACCOUNT</button>}
						</div>
					
					</div>

            </div>}

			
		</div>
                            </div>
                        </form>
          
        </div>  
    </div>
       
    </div>
    </section> 
</> 

 );
        
}

export default React.memo(Registration)  