import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const APP_KEY =process.env.REACT_APP_APP_KEY;
   export const encrypt=data=>CryptoJS.AES.encrypt(data, APP_KEY).toString();
 
  export const decrypt=data=>{
  var byte =  CryptoJS.AES.decrypt(data, APP_KEY)
  return byte.toString(CryptoJS.enc.Utf8)
  } 
