import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import {ServerUrl, ImagesUrl, Token, Currency, config, schoolName, applicationID, PSKTESTPublic, PSKLIVE} from '../component/include'
import { longDate, FormatNumber, getClassName } from '../component/globalFunction'
import { useParams } from 'react-router'
import ProfileCard from './profileCard'
import { usePaystackPayment } from 'react-paystack';
//import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
const PreviewApp=()=>{
	
    let params = useParams()
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [schoolClass, setSchoolClass]=useState([])
const [allClasses, setAllClases]=useState([])
    const [content, setContent] = useState([])
const [province, setProvince] = useState([]);
    const [lga, setLga] = useState([])
    const [errors, setErrors] = useState({});

    const [country, setCountry]=useState([])
var amount = (Number(content.feeCharge)+ (0.016 * Number(content.feeCharge))+100) * 100

    var txRef = (new Date()).getTime().toString();
const paystackConfig = {
    reference: txRef,
    email: content&&content.email,
    amount: amount,
    publicKey: PSKLIVE 
};

const onSuccess = (ref) => {
 handleSubmitPayment(ref, 'Paystack')
};

const onClose = () => {
  // implementation for  whatever you want to do when the Paystack dialog closed.
  //console.log('closed')
}

const handlePaystackPayment = usePaystackPayment(paystackConfig);



const handleSubmitPayment =(response, platform)=>{
 


    const fd = new FormData();
    fd.append("transaction_id", response.transaction)
    fd.append("txref", response.reference)
    fd.append("amount", content&&Number(content.feeCharge))
    fd.append("rawResponse", JSON.stringify(response, null, 2))
    fd.append("platform", platform)
    fd.append("jwt", Token)
    fd.append("paymentStatus", 'Paid')

    fd.append("email", content&&content.email)
    fd.append("fullname", content&&content.studentName)
    fd.append("applicationID", content&&content.applicationID)

    fd.append("amountTopay", (amount/100).toFixed())

    let url = ServerUrl+'/save_controller/tbl_payment_history';


   setNotice({...notice,   isLoading: true})
  axios.post(url, fd, config)
  .then(result =>{
   if(result.data.type ==='success'){

    Alerts('Success!', 'success', 'You have successfully made payment')
    window.open('/preview_application/'+content.email, '_self')
    
    //window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
    }else{
      setErrors({...errors, errorMessage: JSON.stringify(result.data,  null, 2)})
    }    
  })
  .catch((error)=>{
    setErrors({...errors, errorMessage: error.message})
  }).finally(()=>{
    setNotice({...notice, 
      isLoading: false}) 
  }) 
}




const fetchContent =()=>{


  setNotice({...notice,  isLoading: true}) 
    var sql ="select t.applicationName, a.feeCharge, s.startYear, r.amountPaid, r.dateSubmitted, r.applicationID, r.dateCreate, r.passport, r.classID, r.sectionID, r.applicationType, r.schoolSession, r.admissionStatus, r.studentName, r.paymentStatus, r.gender, r.email,  r.birthDate, r.placeOfBirth, r.submitted, r.country, r.stateCode, r.lga, r.schoolAttended, r.religion, r.tribe, r.languageSpoken, r.address, r.fatherName, r.fatherPhone, r.motherName, r.motherPhone, r.disabilityStatus, r.disabilityDetails, r.transferImage, r.recordImage, r.birthImage from tbl_admission_request r, tbl_admission_application t, tbl_school_admission a, tbl_school_session s  where s.ID = a.schoolSession and t.ID = a.applicationType and r.applicationType = a.ID and r.applicationID ='"+applicationID+"' and email ='"+params.email+"'  Limit 1";

    const fd = new FormData();
    fd.append("sql", sql);

  fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{ 
        
        if(result.data.length!==0){
        setContent(result.data[0])
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{

 setNotice({...notice,  isLoading: false}) 
    })
    
}


const fetchCountryName =(code)=>{
  const result = country&&country.filter(list=>list.iso2===code);
const answer = result.length!==0?result[0].name:''
  return answer
}

const fetchClass = async ()=>{

  const fd = new FormData();
  fd.append('jwt', Token);
  let url = ServerUrl+'/fetchField/tbl_school_class/schoolClass/ID/1'
      const {data} = await axios.post(url, fd, config)
      setSchoolClass(data);
  
}


const fetchCountry =()=>{
  var sql ="Select c.iso2, c.name from tbl_countries c";  
  const fd = new FormData();
  fd.append("sql", sql);
  fd.append("jwt", Token);
  let url = ServerUrl+'/fetchBySql_controller';
  axios.post(url, fd, config).then(result=>{ 
    if(result.data.length!==0){
      setCountry(result.data)
    }
      
  })
  .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
  })

}
const handleFetchState = ()=>{
  const fd = new FormData(); 
  var sql ="Select s.name from tbl_states s, tbl_admission_request st where st.stateCode = s.id and st.applicationID ='"+applicationID+"'";  
  fd.append("sql", sql);
  fd.append("jwt", Token);
  let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result =>{
  if (result.data.length!==0){
    setProvince(result.data[0].name)
  }else{
    setProvince('')
  }
})   
}

const handleFetchCities = ()=>{
  const fd = new FormData(); 
  var sql ="Select c.name from tbl_cities c, tbl_admission_request st where st.lga = c.id and st.applicationID ='"+applicationID+"'";  
  fd.append("sql", sql);
  fd.append("jwt", Token);
  let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result =>{

  if (result.data.length!==0){
      setLga(result.data[0].name)
  }else{
      setLga('')
  }
})   
}



const SubmitForm=(event)=>{
    event.preventDefault()
        setNotice({...notice, 
            isLoading: true})     
            let close =   document.getElementById('btnWarningDialog-sform')
            close.click();
      const fd = new FormData();
      fd.append('jwt', Token);
      fd.append('submitted', 'true')
      fd.append('email', content.email)
      fd.append('studentName', content.studentName)
      fd.append('applicationID', content.applicationID)
      
      window.scrollTo({top:0, left:0, behavior:'smooth'})
      let url = ServerUrl+'/save_controller/tbl_final_submits';
      axios.post(url, fd, config)
      .then(response =>{
      if(response.data.type === 'success'){
        Alerts('Success!', 'success', response.data.message)
fetchContent()
        setTimeout(()=>{

          window.open(ServerUrl+'/printout/student_application_summary.php?applicationID='+content.applicationID+'&token='+content.email+'&session='+Token, '_blank')
        }, 3000)
                   } else{
              //Alerts('Error!', 'danger', response.data)
              window.scrollTo(0,0)
        setErrors({...errors, errorMessage: JSON.stringify(response.data, null, 2)})
                }   
            })
            .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
    
              setNotice({...notice, 
                isLoading: false
              })
            })  
    }


    
//redirect_url: window.location.href,
//LIVE API FLWPUBK-bd0b293af3bda1b60ff75fd7836c5a46-X
//TEST API  FLWPUBK_TEST-ba1f52064fac16ac5f429566759815b2-X
const flutterConfig = {
  public_key: 'FLWPUBK-bd0b293af3bda1b60ff75fd7836c5a46-X',
  tx_ref: applicationID,
  amount: Number(content.feeCharge),
  currency: "NGN",
  country: "NG",
  payment_options: 'card,mobilemoney,ussd',
  customer: {
    email: content.email,
    phonenumber: '',
    name: content.studentName,
  },
  customizations: {
    title: schoolName,
    description: 'Application Form Fee',
    logo: ImagesUrl+'/about/logo.png',
  },
};


const handleFlutterPayment = [];//useFlutterwave(flutterConfig);

    useEffect(()=>{      
    handleFetchCities()
    handleFetchState()
    fetchCountry()
        fetchContent()
    fetchClass()

},[]);




   return ( <Template menu="overview" >{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">

   <WarningModal message='Are you really sure?. Once submitted this cannot be change' handleAction={SubmitForm} mID={'sform'} /> 

                <div className="page-wrapper">
                <div className="page-body m-t-50">

              

                {errors.errorMessage?
    <div className="alert alert-danger mt-3">
	<p className="icontext"><i className="icon text-danger fa fa-times"></i> {errors.errorMessage}</p>
</div>:''}
{content.length!==0?
                <div className="row">
                <div className="col-xl-9 col-md-12">

<div className="card z-depth-0">

		<div className="card-block">

	  {/* {content.paymentStatus==='Paid'?
	<div className="ribbon-wrapper">
  <div className="ribbon">PAID</div>
</div>:''} */}

       <div className="row">
<div className="col-md-12 table-responsive">
       <table className="table ">
	<tbody>
   <tr style={{ marginBottom:'0px'}}>
                <td className="text-center" >
                        
                        <img id="viewSchoolLogo2" style={{ maxHeight:'100px' }}  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/headerLogo.png`}   alt={schoolName}  />
 </td>
                </tr> 
        <tr style={{textAlign:'center', marginTop:'0px'}}>
		<td >
			<h3 style={{textAlign:'center',background:'#404E67', color:'#fff',lineHeight:'28px',padding:'3px'}}>{content.applicationName.toUpperCase()}<br/> <span>{content.startYear} SESSION</span></h3>


	{content.paymentStatus=='Pending'?
	<h5 style={{color:'red'}}>Note: You will not be able to submit this form until you make payment</h5>:''}
		</td>
	</tr>
	</tbody></table>







  
	<table className="table table-bordered table-condensed" ><tbody>
	<tr>
			<td colSpan="3"><h2 style={{textAlign:'center',borderTop:'none !important'}}>{content.studentName.toUpperCase()}</h2></td>
		</tr>
		<tr style={{textAlign:'center'}} >
			<td><h5>APPLICATION NUMBER<br/><b>{content.applicationID}</b></h5></td>
			<td ><h5>AMOUNT <br/><b>{Currency+FormatNumber(content.feeCharge)}</b></h5></td>
			
			<td><h5 >PAYMENT STATUS<br/>{content.paymentStatus==='Paid'?<b className='text-success'>{content.paymentStatus}</b>:<b className='text-danger'>{content.paymentStatus}</b>}</h5></td>
		</tr>
		<tr style={{border: 'solid 1px #999',backgroundColor:'#404E67', color:'#fff',fontSize:'18px'}}>
			<td colSpan="3"><b>1:&nbsp;&nbsp;ADMISSION DETAILS </b></td>
		</tr>
	
	<tr>
    <td ><b>Date Started:</b></td>

			<td>{longDate(content.dateCreate)} 	</td>
      <td rowSpan="4" > 
<img id="viewPassport" src={ImagesUrl+'/students/'+content.passport}  onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+content.gender+".png"}}  width="120px" height="140px" alt="User-Profile" /></td>

	</tr>
	<tr>
		<td><b>Date Submitted:</b></td>
		<td> {content.dateSubmitted!=='0000-00-00'?longDate(content.dateSubmitted):'Not Yet Submit'}</td>
	</tr>
	<tr>
		<td><b> Class Applied:</b></td>
		<td>{getClassName(content.classID, content.sectionID, schoolClass)}</td>
	</tr>
		<tr>
			<td><b>Admission Status:</b></td>
			<td colSpan="2"> {content.admissionStatus}</td>
		</tr>
	</tbody>
</table>


<table className="table table-bordered table-condensed" style={{marginTop:'25px'}}>
		<tbody>
			<tr style={{border: 'solid 1px #999',backgroundColor:'#404E67', color:'#fff',fontSize:'18px'}}>
			<td colSpan="3"><b>2:&nbsp;&nbsp;BIODATA </b></td>
		</tr>
		<tr>
			<td >
				<b>Gender:</b><br/>{content.gender}
			</td>
			<td >
				<b>Date of Birth:<font style={{color:'#f33'}}>*</font></b><br/> {longDate(content.birthDate)}
			</td>
			<td>
				<b>Place of Birth:</b><br/>{content.placeOfBirth}
			</td>
		</tr>

		<tr>
			<td style={{width:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}><b>Email Address:</b><br/>
			{content.email}</td>

			<td><b>State of Origin &amp; City:<font style={{color:'#f33'}}>*</font></b><br/>
			{province&&province},  &nbsp;
			{lga&&lga}</td>
			<td><b>Country of Origin:<font style={{color:'#f33'}}>*</font></b><br/>{country&&fetchCountryName(content.country)}</td>
		</tr>

		<tr>
			<td><b>Address:</b><br/>
			{content.address}
			
			</td>
			<td><b>Tribe &amp; Language:<font style={{color:'#f33'}}>*</font></b><br/>{content.tribe},  {content.languageSpoken}</td>
			<td><b>Religion:<font style={{color:'#f33'}}>*</font></b><br/>{content.religion}</td>
		</tr>
	</tbody>
</table>
<br/>


       <table className="table table-bordered  table-full-width">
       <tbody>
       <tr style={{border: 'solid 1px #999',backgroundColor:'#404E67', color:'#fff',fontSize:'18px'}}>
			<td colSpan="3"><b>3:&nbsp;&nbsp;PARENT DETAILS</b></td>
		</tr>
                <tr>
                    <td ></td>
                    <td >Name</td>
                    <td>Telephone</td>
                </tr>
                <tr>
                    <td >Father </td>
                    <td ><b>{content.fatherName}</b></td>
                    <td ><b>{content.fatherPhone}</b></td>
                </tr>
                <tr>
                    <td >Mother </td>
                    <td ><b>{content.motherName}</b></td>
                    <td ><b>{content.motherPhone}</b></td>
                </tr>
            </tbody>
       </table>


        <table id='paymentTable' className="table table-striped table-bordered  table-full-width">
    

            <thead>
            <tr style={{border: 'solid 1px #999',backgroundColor:'#404E67', color:'#fff',fontSize:'18px'}}>
			<td colSpan="4"><b>4:&nbsp;&nbsp;EDUCATIONAL BACKGROUND</b></td>
		</tr>
                <tr>
                <th>School Attended</th>
                <th>Year</th>
                <th> Program /Grade</th>
                <th>Remark</th>
                </tr>
            </thead>
            <tbody >
					{JSON.parse(content.schoolAttended).map((item, id)=>{
					return	<tr key={id}>
                        <td>{item.institution}</td>
					<td>{item.awardYear}</td>
                    <td>{item.program}</td>
					<td>{item.remarks}</td>
					
						</tr>
					})}
				</tbody>
        </table>
<br/>
        <h6 className="text-blue m-b-0">

<b>DECLARATION:</b><br/> <br/> </h6><p>I, <b>{content.studentName},</b> hereby declare that all the information given in this form and the supporting documents provided, is to the best of my knowledge and belief correct, any false or incomplete information given in this form may lead to disqualification and denial of admission into <span ><b>{schoolName}</b></span>

                                                        </p>
 </div>	 </div> 


 </div>
        </div>


        </div>


        {content.submitted==='false' && content.paymentStatus==='Pending' ?  <div className="col-xl-3 col-md-6">
            <div className="card user-card">
                <div className="card-block text-center">
               
                    <h6 className="m-t-25 m-b-10">{content.studentName}</h6>
                    <p className="text-muted">{content.applicationID}</p>

<div>
<label>Amount: <b>{Currency+FormatNumber(content.feeCharge)}</b></label><br/>
<label>Service Charge: <b>  {Currency+FormatNumber((0.016 * Number(content.feeCharge))+100)}</b></label>

</div>
                     
                   

{Number(content.feeCharge)>Number(content.amountPaid)?<button type="button"   onClick={() => {
                handlePaystackPayment(onSuccess, onClose)
            }}   className="btn btn-success btn-round"  ><i className="fa fa-credit-card"></i> PAY ONLINE NOW</button>:''}


{/* 
	{Number(content.feeCharge)>Number(content.amountPaid)?<button type="button" onClick={() => {
          handleFlutterPayment({
            callback: (response) => {
              var transaction_id = response.transaction_id; 
			  var txref = response.tx_ref; 
			 var amount = response.amount
			 handlePaymentReport(response)
             if (response.status == "successful" ){
             makePayment(amount)
            } 
              
            },
            onClose: () => {

              //closePaymentModal(); 
            },

          });
        }}   className="btn btn-success btn-round"  ><i className="fa fa-credit-card"></i> Make Payment Online</button>:''} */}
                </div>
            </div>
        </div>:
<ProfileCard />
                }
       
        

        </div>:''}


        {content.submitted==='false'? <div className="row ">

<div className="col-xl-9 col-md-12 text-center">
<a href='/overview' className="btn btn-success" > <i className="fa fa-arrow-left"></i> Previous</a>
&nbsp;


{content.paymentStatus!=='Pending'?
<button type="butoon" className="btn btn-danger   no-print"  data-toggle="modal"   data-target={`#warningdialog-sform`}> <i className="fa fa-save"></i> Submit Application</button>:''}
</div></div>:''
}

{content.submitted==='true'?
<div className="col-xl-9 col-md-12 text-center">

<form method='POST' action={ServerUrl+'/printout/student_application_summary.php?applicationID='+content.applicationID+'&token='+content.email+'&session='+Token} target='_blank'>
                <input type='hidden' name='jwtToken' value ={Token} />

<button type="submit" className="btn btn-inverse   no-print" id="printForms" > <i className="fa fa-print"></i> Print</button>
              
        
        </form>




</div>:''}

            </div>
            </div>
            </div>

</Template> 
 );
        
}

export default React.memo(PreviewApp) 