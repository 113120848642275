import React, {useState, useEffect} from 'react'
import {Alerts} from '../component/notify'
import {ServerUrl, ImagesUrl, config, FrontUrl} from '../component/include'
import axios from 'axios'
import Preloader from '../component/preloader'
import { LoadEffect } from '../component/loader'

import Header from '../component/header_no_login'

 const RecoverPassword =()=> {
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [applicant, setApplicant] = useState({
        password:'',
        confirmPassword:''
    })

    const [errors, setErrors] = useState({});

    const handleChange = event =>{		
        let {name, value} = event.target;	
        setApplicant({...applicant, [name] : value });
       setErrors({...errors, [name]:''});
    }





    const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(applicant));
        setIsSubmitting(true);
    }
    const ValidateInput =(values)=>{
        let errors = {};   
       
        if(values.password.length <6){
            errors.password ='Please enter minimum of 6 characters';
        } 

        if(values.confirmPassword.length <6){
            errors.confirmPassword ='Please enter minimum of 6 characters';
        }


      if(values.password!==values.confirmPassword){
          errors.password ='Password does not match.';
      }
        
        return errors;
        }



		useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])

            var query = new URLSearchParams(window.location.search)
            var myemail = query.get("myemail")
            var mytoken = query.get("mytoken")

	function submit(){  
		setNotice({...notice,  isLoading: true}) 	
           
        let formData = applicant;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }

        fd.append('email', myemail)
           let url = ServerUrl+'/update_controller/tbl_reset_applicant_password';
		axios.post(url, fd, config)
		.then(response =>{

	    if (response.data.type ==='success'){
			setApplicant({...applicant, rsMessage:response.data.message, email:'', readyLogin:'true'})
			
			}else if (response.data.type ==='error'){
				setApplicant({...applicant, rsMessageError:response.data.message, email:''})
				
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
			 
		}).finally(()=>{
            setIsSubmitting(false)
            setNotice({...notice,  isLoading: false }) 
            
        setApplicant({
            password:'',
            confirmPassword:''
        })
    }) 
}


function verifyLink(){  
		    
    
    
    if(myemail==='null'  || mytoken==='null' || mytoken==='' || myemail===''){
        window.open('/applicant_login', '_self')
    
    }else{
        setNotice({...notice, isLoading: true}) 
    
          const fd = new FormData();
          fd.append('email', myemail)
          fd.append('token', mytoken)
    
          let url = ServerUrl+'/save_controller/tbl_verify_password_request';
          axios.post(url, fd, config)
          .then(response =>{
          if(response.data.type === 'success'){
                
            setApplicant({...errors, successMessage: response.data.message})
    
                }else if (response.data.type ==='error'){
                  setErrors({...errors, rsMessage: response.data.message})
                }    
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
                 
            }).finally(()=>{
                setNotice({...notice, 
                    isLoading: false
                }) 
            })    
        } 
    }
    
    
    
    useEffect(()=>{  
        verifyLink()
    },[]);
    

        return (<>
          <Preloader />
          <div id="pcoded" className="pcoded load-height">
        <div className="pcoded-overlay-box"></div>
        <div className="pcoded-container navbar-wrapper">

        <Header />

        </div>

		  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
		  <section  className="with-header">
        <div className="container">

        <div style={{height:'60px'}}>
		</div>

        {applicant.successMessage?    <div className="row">
                <div className="col-sm-6">
                    



	<div className="auth-box card bg-c-lite-green">
		<div className="card-block">
		
			<div className="card-body">

            <div className='text-white'><h4>Change your password</h4>
			
<br/>
			<h5 className='text-align'> Your new password should be at least 7 characters longs and must contain one letter and at least one number or symbol.</h5>
<br/>
			<h5 className='text-align'>Once you have updated the password, you can return to <a href={FrontUrl}>{FrontUrl}</a> and log in with the new password.</h5>

			</div>

			</div>
			
		
		</div>
                            </div>

                            </div>
                            <div className="col-sm-6">
                    
	<form className="md-float-material form-material">
		
	<div className="auth-box card">
		<div className="card-block">
			<div className="row m-b-20">
				<div className="col-md-12">
			
			
{applicant.rsMessage?
				<div className='alert alert-success'>{applicant.rsMessage}</div>:''}
       
       {applicant.rsMessageError?
				<div className='alert alert-danger'>{applicant.rsMessageError}</div>:''}

					<h3 className="text-center">Change your password</h3>
				</div>
			</div>


			<div className="form-group form-primary">

            <input type="password" id="password" name="password"  value={applicant.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control formerror' : 'form-control'} />
                            <span className=" form-bar error">{errors.password }</span>

			</div>

            <div className="form-group form-primary">

            <input type="password" name="confirmPassword" autoComplete='off' role={'presentation'} placeholder="Confirm Password"  value={applicant.confirmPassword}  onChange={handleChange} className={errors.confirmPassword  ? 'form-control formerror' : 'form-control'} />
                            <span className=" form-bar error">{errors.password }</span>

			</div>


			<div className="row m-t-30">
				<div className="col-md-12">
					{applicant.readyLogin?<a className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20" href="/applicant_login">Click Here to Login.</a>:
					<button type="button" onClick={handleSubmit} className="btn btn-primary btn-md btn-block waves-effect waves-light text-center m-b-20">Update My  Password</button>}
				</div>
			</div>
            <hr/>
			<div className="row">
				<div className="col-md-12">
					<p className="text-inverse text-left">
						
						<a href="/" className='btn btn-inverse'><b className="f-w-600">Create New Account</b></a>
					
						<a href="/applicant_login" className='btn btn-success pull-right'>Back to Login.</a>
					
					</p>
				</div>
			</div>
		</div>
                            </div>
                        </form>



						
						</div>
            </div>:
            
            ''}

{errors.rsMessage?
				<div className='alert alert-danger'>{errors.rsMessage}</div>:''}
        </div>
    </section>    
    </div>

         
</> 

 );
        
}

export default React.memo(RecoverPassword) 