import Cookies from 'js-cookie'
var CryptoJS = require('crypto-js')

export const APP_KEY =process.env.REACT_APP_APP_KEY;
export const FormatNumber = (num)=>{
    
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}





export const studentName = Cookies.get('pageappstnm')?CryptoJS.AES.decrypt(Cookies.get('pageappstnm'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'

export const applicationID = Cookies.get('pageappid')?CryptoJS.AES.decrypt(Cookies.get('pageappid'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'

export const Token = Cookies.get('pageapptk')?CryptoJS.AES.decrypt(Cookies.get('pageapptk'), APP_KEY).toString(CryptoJS.enc.Utf8):'null'


export const afterToken = Cookies.get('_pagetmapp')?Cookies.get('_pagetmapp'):'null'

export const beforeToken= Cookies.get('_pagebtmapp')?Cookies.get('_pagebtmapp'):'null'


export const config = {
    headers: { 'content-type': 'multipart/form-data'}
} 
export const Currency = '₦'



export  const ServerUrl = process.env.REACT_APP_SERVER_URL
export  const ImagesUrl = process.env.REACT_APP_IMAGES_URL
export  const StudentUrl = process.env.REACT_APP_STUDENT_URL
export  const LocalUrl = process.env.REACT_APP_LOCAL_URL
export const schoolName = process.env.REACT_APP_SCHOOL_NAME
export  const FrontUrl = process.env.REACT_APP_APPLICANT_URL
export  const loginUrl = FrontUrl+'/applicant_login';


export const helpEmail = 'greatmindsmia@gmail.com'
export const helpMobile = '08037340746, 07067153316'



 export  const PSKLIVE = ''; 
 export  const PSKTESTPublic = '';
