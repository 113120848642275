import React from 'react'


const Sidebar = (props)=>{
    return <nav className="pcoded-navbar">
    <div className="pcoded-inner-navbar">
        <ul className="pcoded-item pcoded-left-item">
            
<li className={props.menu==='overview'?'active':''}>      <a href="/overview">
                    <span className="pcoded-micon"><i className="feather icon-home"></i></span>
                    <span className="pcoded-mtext"> OVERVIEW</span>
                    <span className="pcoded-mcaret"></span>
                </a>  </li>
<li className={props.menu==='status'?'active':''}>       <a href="/status">
                    <span className="pcoded-micon"><i className="feather icon-user"></i></span>
                    <span className="pcoded-mtext">APPLICATION STATUS</span>
                    <span className="pcoded-mcaret"></span>
                </a> </li>

<li className={props.menu==='password'?'active':''}>       <a href="/change_password">
                    <span className="pcoded-micon"><i className="feather icon-lock"></i></span>
                    <span className="pcoded-mtext">CHANGE PASSWORD</span>
                    <span className="pcoded-mcaret"></span>
                </a> </li>

        </ul>
    </div>
</nav> 
}
export default React.memo(Sidebar)